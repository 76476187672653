import { abilitiesPlugin, Can } from "@casl/vue";
import Vue from "vue";
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import VueLogger from "vuejs-logger";
import App from "./App.vue";
import { UIHelper } from "./lib/UIHelper";
import VueAppInsights from "./plugins/AppInsightsPlugin";
import i18n from "./plugins/I18nPlugin";
import vuetify from "./plugins/VuetifyPlugin";
import "./registerServiceWorker";
import router from "./router";
import store from "./store/VuexPlugin";
import { ConfigModule } from "./store/modules/config";
import { UserModule } from "./store/modules/me-user.store";

const isProduction = process.env.NODE_ENV === "production";

const options = {
  isEnabled: true,
  logLevel: isProduction ? "error" : "debug",
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true
};

// link for toast-notification doc: https://maronato.github.io/vue-toastification/
const toastOptions = {
  position: "bottom-right"
};

Vue.use(Toast, toastOptions);
Vue.use(VueLogger as any, options);
Vue.use(UIHelper);
Vue.use(abilitiesPlugin, UserModule.abilities);
Vue.component("Can", Can);

Vue.config.productionTip = false;

const url = ConfigModule.mrfiktivBaseUrl + "/config";
Vue.use(VueAppInsights, {
  baseName: "app",
  id: ConfigModule.appInsightsKey,
  url: url,
  cookie: false,
  router
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
